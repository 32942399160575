<template>
    <div class="reportLog defaultReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="inline-block">
                    <span class="lable-txt">营业日期：</span>
                    <el-date-picker class="from-date"
                        :clearable="false"
                        :editable="false"
                        v-model="businessHours"
                        type="date"
                        placeholder=""
                        :default-value="new Date()" >
                    </el-date-picker>
                </div>
                <div class="inline-block">
                    <span class="lable-txt">状态查询：</span>
                    <el-select v-model="searchType" placeholder="所有" >
                        <el-option :value="0" label="所有"></el-option>
                        <el-option :value="1" label="已结"></el-option>
                        <el-option :value="2" label="未结"></el-option>
                    </el-select>
                </div>
                <div class="inline-block">
                    <div class="flex-box">
                        <div class="lable-txt">销售渠道:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="channelAutoIDs" placeholder="请选择" :data="treeOrderChannel" title="销售渠道选择"/>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <key-board-input class="search from-input search-input"
                            type="text" v-model="selectContent" placeholder="按菜品编号、名称、助记码检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                        <!-- <div class="from-input search-input"><input type="text" v-model="selectContent"  placeholder="按菜品编号、名称、助记码检索" /></div> -->
                        <div class="search-flex">
                            <div class="bnt-search" @click="loadData()" >查询</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table isFooter">
                <el-table class="el-table--scrollable-y" ref="elTable"  :data="tableListPage" 
                 border 
                 show-summary
                :summary-method="getSummaries"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                    <el-table-column fixed prop="Eat_DeskName" label="台号" min-width="100" align="left"></el-table-column>
                    <el-table-column fixed prop="Eat_XFCode" label="菜品编码" min-width="100" align="left"></el-table-column>
                    <el-table-column fixed prop="Eat_XFName" label="菜品名称" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Eat_XFSize" label="单位" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_Number" label="数量" min-width="60" align="right"></el-table-column>
                    <el-table-column prop="Eat_Czy" label="点菜员"  min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Eat_Time" label="点菜时间"  min-width="130" :formatter="dateFormat"></el-table-column>
                    <el-table-column prop="Mas_Name" label="备注" min-width="120" align="left"></el-table-column>
                    <el-table-column prop="Eat_StationName" label="下单站点" min-width="120" align="left"></el-table-column>
                    <el-table-column prop="Food_Speed" label="厨房状态" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_AssNun" label="辅助数量" align="right" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_AssSize" label="辅助单位" align="left" min-width="80"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                :data="tableFilter"
                @page-change="(list)=>tableListPage=list" 
                :pageSizes="[20,30,50,100]"
                :pageSize="pagesize"
                 @size-change="(size)=>pagesize=size"></table-page-btn>

        </div>
     </div>
</template>
<script>
/**点单流水日志表 */
export default {
    name:'reportLog',
    data(){
        return {
            currentPage:1,
            pagesize:20,//每页的数据条数
            userInfo:'',
            tableList:[],
            //分页显示数据
            tableListPage:[],
            /**营业时间 */
            businessHours:new Date(),
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            //状态查询
            searchType:0,
            //台号检索
            selectContent:"",
            //渠道数据
            orderChannel:null,
            //渠道id
            channelAutoIDs:[],
        }
    },
    computed:{
        summaryTableList(){
            let total={
                Eat_Number:0,
                Eat_XFName:"",
                Eat_AssNun:0
            };//合计
            this.tableFilter?.forEach((it)=>{
                //合计
                total.Eat_Number+=it.Eat_Number||0;
                total.Eat_AssNun+=it.Eat_AssNun||0;
            });
            if(this.tableFilter?.length>0){
                total.Eat_XFName=this.tableFilter?.length+"条记录";
                total.Eat_Number=parseFloat(total.Eat_Number.toFixed(2));
                total.Eat_AssNun=parseFloat(total.Eat_AssNun.toFixed(2))
            }
            return total;
        },
        //条件筛选
        tableFilter(){
            let list=this.tableList||[];
            if(list && list.length>0){
                if(this.selectContent!=''){
                    let seach=this.selectContent.toUpperCase();
                    list=list.filter(it=>
                        (it.Eat_XFCode||'').toUpperCase().indexOf(seach)>=0 
                        || (it.Eat_XFName||'').toUpperCase().indexOf(seach)>=0
                        || (it.PINYIN||'').toUpperCase().indexOf(seach)>=0
                    );
                }
            }
            return list;
        },
        //渠道数据
        treeOrderChannel(){
            let treeData=[];
            this.orderChannel?.map((group)=>{
                let json={
                    id:group.OrderChannelKind_AutoID,
                    name:group.OrderChannelKind_Code+group.OrderChannelKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.OrderChannelList){
                    group.OrderChannelList.map(item=>{
                        let children= Object.assign({
                            id:item.OrderChannel_AutoID,
                            name:item.OrderChannel_Code+'-'+item.OrderChannel_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        if(this.userInfo && this.userInfo.Rpt_Date){//营业日期
            this.businessHours=this.userInfo.Rpt_Date;
        }
        //渠道数据
        this.$cacheData.OrderChannel().then((d)=>{
            if(d){
                this.orderChannel=d;
            }
        }).catch((e)=>{
            this.$message.error('加载渠道数据失败：'+e.message);
            console.log('加载渠道数据失败：'+e);
        })
    },
    watch:{
    },
    methods:{
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.elTable,
                titleName:"点单流水日志表",
                list:this.tableFilter,
                colFormat:(row,column)=>{
                    let json={};
                    //t 类型  b布尔值，n数字，e错误，s字符串，d日期，z存根
                    if(column.property=="Eat_Number"){
                        json.t='n';
                    }
                    return json;
                }
            })

            
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList;
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]=total[column.property];
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**查询 */
        loadData(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Search_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),//营业日期
                Search_Type:this.searchType,
                ChannelIDList:this.channelAutoIDs,//渠道
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.requestConfig({timeout:60*1000}).post("Bestech.CloudPos.FoodSaleLogReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody;
                }else{
                    this.tableList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
    }
}
</script>

<style lang="scss" >
@import './reportLog.scss'
</style>